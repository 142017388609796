<template>
  <div class="box">
    <div class="text">
      <div class="title">微企乐用户隐私协议</div>
      <div>
        更新时间：2021年10月21日
        <br />生效时间：2021年10月21日
        <br />微企乐（以下亦称“我们”）深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定
        “隐私政策”并特别提示：希望您在使用微企乐及相关服务前仔细阅读并理解本指引，以便做出适当的选择。
        <br />
        <br />本指引将帮助你了解：
        <br />&nbsp;&nbsp;我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本指引而采用强制捆绑的方式一揽子收集个人信息。
        <br />&nbsp;&nbsp;当您开启相关功能或使用相关服务时，为实现功能、服务所必需，我们会收集、使用必要的个人信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要个人信息，您均可以拒绝提供且不影响您开启或使用其他功能或服务。我们将在本指引中逐项说明哪些是必要信息。
        <br />&nbsp;&nbsp;如果您未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了帐号，我们会根据帐号信息实现信息推送。
        <br />&nbsp;&nbsp;精确地理位置、摄像头、麦克风、相册、存储权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。
        <br />&nbsp;&nbsp;本指引适用于您通过微企乐移动应用程序（以下称“微企乐APP”）、微企乐网页版、供第三方网站和应用程序使用的微企乐软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务（在本指引中，统称为“微企乐及相关服务”）。
        <br />&nbsp;&nbsp;下文将帮助您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；其中，有关您个人信息权益的重要内容我们已用加粗形式提示，请特别关注。
      </div>
      <br />一、我们可能收集的用户信息
      <br />&nbsp;&nbsp;我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
      <br />1、注册成为微企乐个人用户
      <br />&nbsp;&nbsp;为注册成为微企乐个人用户，以便我们为您提供基础的服务，诸如一键测额，分享海报，联系顾问等功能，需要收集您的手机号码及短信验证码以注册并创建微企乐账号，否则您将不能使用微企乐服务。如果您仅需使用浏览、搜索微企乐展示的产品、功能及服务介绍，您不需要注册成为微企乐用户并提供上述信息。
      <br />2、使用微企乐服务过程中收集信息
      <br />&nbsp;&nbsp;当您在使用微企乐服务过程中，为向您提供更契合您需求的微企乐服务、交互展示、搜索结果、识别账号异常状态，维护微企乐服务的正常运行，改进及优化您对微企乐服务的体验并保障您的账号安全，包括您使用微企乐服务以及使用方式的信息，并将这些信息进行关联：
      <br />1）使用信息
      <br />&nbsp;&nbsp;我们会收集您作为微企乐个人用户使用微企乐服务时提交或产生的信息，以及您作为微企乐企业/组织用户的最终用户使用微企乐服务时提交或产生的信息。如您需使用微企乐一键测额功能，您需提供您的位置信息、企业名称和社会信用统一代码；如您需要使用微企乐专属顾问功能，您需提供您的手机号码及短信验证码等。
      <br />2）完善个人资料
      <br />&nbsp;&nbsp;您完善个人资料时，我们会以弹窗形式请求您授权调用存储（或照片、相机）权限，以便我们获取您要用于上传的头像。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />3）联系专属顾问
      <br />&nbsp;&nbsp;在您联系专属顾问时，我们会请求您授权微企乐调用电话权限，以便直接拨打专属顾问电话。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />4）查看产品
      <br />&nbsp;&nbsp;在您查看微企乐产品时，我们可能会提供显示位置的服务，当您选择显示位置时，我们会请求您授权微企乐调用地理位置权限，并收集与本服务相关的位置信息。您如果拒绝授权提供精确地理位置信息，将无法使用此功能，但不影响您正常使用微企乐的其他功能。
      <br />二、我们可能如何使用用户信息
      <br />1、在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
      <br />2、帮助我们设计新服务，改善我们现有服务；
      <br />3、使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求；
      <br />4、软件认证或管理软件升级； 5、让您参与有关我们产品和服务的调查。
      <br />&nbsp;&nbsp;为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
      <br />三、我们可能与第三方共享的用户信息
      <br />在下列情况下，我们可能会共享您的个人信息：
      <br />1、其他注册用户就您在微企乐活动中的违约行为发起诉讼程序时，我们将应该等注册用户的请求向该等注册用户披露您的用户信息；
      <br />2、应行政、司法部门的要求向该等机构提供您的用户信息；
      <br />3、您通过微企乐平台向第三方合作机构申请相关产品服务时，我们将根据您的授权向第三方提供您的用户信息；
      <br />4、接入第三方软件开发工具包（SDK）或其他应用程序
      <br />•微企乐APP可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。目前微企乐APP中包含的第三方SDK有：
      <br />&nbsp;&nbsp;极光 SDK <br />&nbsp;&nbsp;使用目的：推送消息
      <br />&nbsp;&nbsp;信息类型：设备信息、网络信息、设备标识符、网络监测，设备的IMEI，Wi-Fi_mac地址，位置信息、软件安装列表
      <br />&nbsp;&nbsp;收集信息用途：极光推送服务为APP赋予消息和通知的推送能力。极光推送SDK会收集使用实现服务所必须的个人信息，通过加密的通道将消息和通知推送给您的终端用户。极光推送SDK收集使用的个人信息包括：1）设备信息：用于识别唯一用户，保证消息推送的精准送达；优化推送通道资源，我们会根据设备上不同APP的活跃情况，整合消息推送的通道资源，为开发者提高消息送达率；为开发者提供智能标签以及展示业务统计信息的服务；2）网络信息与位置信息：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域推送的功能
      <br />&nbsp;&nbsp;官网链接：https://www.jiguang.cn/license/privacy
      <br />
      <br />&nbsp;&nbsp;腾讯x5内核 <br />&nbsp;&nbsp;使用目的：网页浏览
      <br />&nbsp;&nbsp;收集信息类型：系统UA、设备IMEI、设备IMSI、IP地址及安卓系统封装用户id
      <br />&nbsp;&nbsp;收集信息用途：使用x5内核、优化x5浏览内核
      <br />&nbsp;&nbsp;官网链接：https://x5.tencent.com/tbs/policy.html
      <br />
      <br />&nbsp;&nbsp;微信登录 <br />&nbsp;&nbsp;使用目的：微信登录
      <br />&nbsp;&nbsp;收集信息类型：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息
      <br />&nbsp;&nbsp;收集信息用途：应用内第三方账号登陆
      <br />&nbsp;&nbsp;官网链接：https://open.weixin.qq.com/
      <br />
      <br />&nbsp;&nbsp;支付宝登录 <br />&nbsp;&nbsp;使用目的：支付宝登录
      <br />&nbsp;&nbsp;收集信息类型：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址）、网络信息
      <br />&nbsp;&nbsp;收集信息用途：应用内第三方账号登陆
      <br />&nbsp;&nbsp;官网链接：https://opendocs.alipay.com/open/01g6qm
      <br />5、关于使用Cookie和同类技术 <br />1）
      在您使用我们的产品与/或服务时，我们可能会使用Cookie和同类技术收集您的一些个人信息，包括：您访问网站的习惯、您的浏览信息、您的登录信息，Cookie和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注册、登录）、便于您查看使用历史（如视频观看历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信息和账号安全性、提升我们的产品和服务等。
      <br />2.）如果您拒绝我们使用Cookie及同类技术收集和使用您的相关信息，您可在浏览器具备该功能的前提下，通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。
      <br />6、我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出短信或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），为了我们向您提供或改善我们的服务；
      <br />•随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移；
      <br />•依据法律要求必须向第三方提供您的用户信息的情形；
      <br />四、我们如何保护用户信息
      <br />&nbsp;&nbsp;我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      <br />五、账户注销
      <br />&nbsp;&nbsp;您可以通过客服在线申请注销或通过其他我们公示的方式申请注销您的账号。当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）；微企乐账号注销完成后，将无法恢复。
      <br />&nbsp;&nbsp;如您在谨慎考虑后仍执意决定注销您的微企乐账号的，您可以在您使用的我们的产品与/或服务的相关功能设置页面或根据操作指引向我们提交注销申请，以微企乐H5为例，其中的账号注销路径为：个人中心—专属顾问—拨打专属顾问电话—告知专属顾问需注销账号。
      <br />&nbsp;&nbsp;我们会在收到您的注销申请，并在验证您的用户身份后的7个工作日内尽快解决。如果您在处置您的个人信息时有任何疑问，您可以通过本隐私政策第七条“联系我们”中公示的联系方式与我们沟通解决。
      <br />六、隐私政策的修改
      <br />&nbsp;&nbsp;由于法律法规的变更，以及为了与互联网的新发展和可能的发展趋势保持同步，我们可能会不定时修改本政策。因此，我们保留自行决定实施此类修改的权利，如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您推送通知或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
      <br />七、联系我们
      <br />1、微企乐及相关服务由北京微企利乐信息技术有限公司提供，注册地址：北京
      北京市海淀区信息路甲28号B座(二层)02C室098号，如果您对个人信息保护问题有投诉、建议、疑问，您可以将问题发送至（biz@weiqilile.com），我们核查并验证您的用户身份后会及时反馈您的投诉与举报。
      <br />2、如对本指引内容有任何疑问、意见或建议，您可通过biz@weiqilile.com与我们联系。
      <br /><br />
      <div style="text-align: right">北京微企利乐信息技术有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  line-height: 30px;
  padding: 15px;
  width: 345px;
  margin: 0 auto;
  padding-top: 66px;
  font-size: 12px;
  .title {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
